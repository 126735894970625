import fileHelper from '../../helpers/fileHelper'
import ApiService from '../../services/api.service'
import { 
  ReleaseDetails, 
  ReleaseModel, 
} from '../models'

export default {
  async releaseTemplates(): Promise<ReleaseModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcConfigurationQueryApi}/PlcConfigurationReleases/configurationReleaseTemplates`)
    return response.data.value
  },
  async releases(plcId: string): Promise<ReleaseModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcConfigurationQueryApi}/Plcs/${plcId}/configurationReleases`)
    return response.data.value
  },
  async release(plcId: string, releaseId: string, language: string): Promise<ReleaseDetails> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcConfigurationQueryApi}/Plcs/${plcId}/configurationReleases/${releaseId}/detail/?resourceLanguage=${language}`)
    return response.data.value
  },
  async componentReleases(plcId: string): Promise<ReleaseModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcConfigurationQueryApi}/Plcs/${plcId}/componentConfigurationReleases`)
    return response.data.value
  },
  async downloadProjectFiles(releaseId: string): Promise<void> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcConfigurationQueryApi}/PlcConfigurationReleases/${releaseId}/projectFiles`, {
      responseType: 'blob',
    })
    fileHelper.saveBlobToFile(response.data, `${releaseId}-projectFiles.zip`)
  },
  async export(releaseId: string): Promise<void> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcConfigurationQueryApi}/PlcConfigurationReleases/${releaseId}/export`)
    fileHelper.saveBlobToFile(JSON.stringify(response.data, null, 2), `${releaseId}-export.json`)
  },
}