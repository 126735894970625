import { ControlTypeV2, PlcOperationState } from '../../common'

export interface InitializePlcInput{
  plcId: string
  fileName: string
} 

export interface PlcOperationReleaseOutput {
  plcs: PlcModel[]
  rooms: RoomModel[]
  devices: DeviceModel[]
  controls: ControlModelV2[]
  activePlcConfigurationReleaseId: string
}

export interface ProjectModel {
  id: string
  name: string
  isDemoProject: boolean
}

export interface PlcModel {
  id: string
  projectId: string
  name: string
  plcOperationState: PlcOperationState | null
  plcOperationStateTimeStamp: string | null
}

export interface RoomModel {
  plcId: string   // was called HouseId EARLIER !
  id: string
  projectId: string
  name: string
  icon: string
  internalName: string
  sortOrder: number
}

export interface DeviceModel {
  id: string
  functionBlockDefinitionId: string
  roomId: string
  plcId: string
  projectId: string
  name: string
  internalName: string
  icon: string
  currentUserAuthorization: string
  canSetIgnoreInCharts: boolean
  isIgnoredInCharts: boolean
  consumptionValidationEnabled: boolean
  canSetConsumptionValidation: boolean
  consumptionProcessOwnerLockEnabled: boolean
  sortOrder: number
  measuringPoints: MeasuringPointModel[]
  energyStatusItems: EnergyStatusItemModel[]
}

export interface MeasuringPointModel {
  id: string
  resourceId: string
  colorGradient: string
  tags: string[]
}

export interface EnergyStatusItemModel {
  id: string
  colorGradient: string
  type: string
}

export interface ControlModelV2 {
  id: string
  type: ControlTypeV2,
  attributes: {
    [attribute: string]: any
  }
  state?: {
    [state: string]: any
  }
  deviceId: string,
  plcId: string,
  projectId: string,
  sortOrder: number,
  isMappedClassicControl: boolean
  hideInScenes: boolean
  // children of ControlType.ArrayContainer only
  parentControlId?: string
  displayName?: string
  index?: number
}

export interface PendingControlCommand {
  controlId: string
  correlationId: string
  timeout: any
  pendingValue: any
}

export enum ControlKinds {
  SWITCH_CONTROL = 'SwitchControl', 
  PARAMETER_CONTROL = 'ParameterControl',
  MONITORING_CONTROL = 'MonitoringControl',
  BUTTON_CONTROL = 'ButtonControl', 
  COLOR_PICKER_CONTROL = 'ColorPickerControl', 
  TEXT_CONTROL = 'TextControl',
  CONTAINER_CONTROL = 'ContainerControl',
}

export enum AppDisplayLevels {
  NOT_DISPLAYED = 'NotDisplayed',
  DISPLAYED_ON_FIRST_LEVEL = 'DisplayedOnFirstLevel',
  DISPLAYED_ON_SECOND_LEVEL = 'DisplayedOnSecondLevel',
  DISPLAYED_ON_THIRD_LEVEL = 'DisplayedOnThirdLevel',
}

export enum EnergyDisplayLevels {
  NOT_DISPLAYED = 'NotDisplayed',
  CONSUMER = 'Consumer',
  PRODUCER = 'Producer',
}

export enum ConfigurationToolCategories {
  STATE = 'State',
  PARAMETER = 'Parameter',
  FUNCTION_TEST = 'FunctionTest',
}

export enum ControlTypes {
  // Text
  UIStringPicker = 'UIStringPicker',
  UIAlarmWithMessage = 'UIAlarmWithMessage',

  // Color
  UIHSL = 'UIHSL',

  // Button
  UIButton = 'UIButton',
  UIUpDownButton = 'UIUpDownButton',
  UIEnoceanDimmer = 'UIEnoceanDimmer',
  UIPlusMinusButton = 'UIPlusMinusButton',

  // Switch
  UISwitch = 'UISwitch',
  UILock = 'UILock',
  UISwitchButton = 'UISwitchButton',
  UICheckbox = 'UICheckbox',
  UIUpDownSwitch = 'UIUpDownSwitch',

  // Monitoring
  UIDottedStateBar = 'UIDottedStateBar',
  UISolidStateBar = 'UISolidStateBar',
  UICircle = 'UICircle',
  UIValue = 'UIValue',
  UIMultiValue = 'UIMultiValue',
  UIMonitoringLock = 'UIMonitoringLock',
  UIAlarm = 'UIAlarm',
  UIOnOff = 'UIOnOff',
  UIText = 'UIText',
  UIErrorMessage = 'UIErrorMessage',

  // Parameter
  UISlider = 'UISlider',
  UIValuePicker = 'UIValuePicker',
  UISoftwareMappingSource = 'UISoftwareMappingSource',
  UISoftwareMappingTarget = 'UISoftwareMappingTarget',
  UIDropdownValueList = 'UIDropdownValueList',

  // Container
  ControlContainer = 'ControlContainer',
  ArrayContainer = 'ArrayContainer',
}

export interface ControlInput<TPayload> {
  controlId: string
  payload: TPayload
}

export type ExecuteControlCommandInput = ControlInput<ExecuteControlCommandPayload>

export type SetControlAppDisplayLevelInput = ControlInput<SetControlAppDisplayLevelPayload>

export type SetDisplayNameInput = ControlInput<SetDisplayNamePayload>

export type SetControlColorGradientInput = ControlInput<SetControlColorGradientPayload>


export interface SetDisplayNamePayload {
  displayName: string
}

export interface SetControlAppDisplayLevelPayload {
  appDisplayLevel: AppDisplayLevels
}

export interface SetControlColorGradientPayload {
  colorGradient: string
}

export interface ExecuteControlCommandPayload {
  command: string
  params: any
  pendingState?: {
    [state: string]: any,
  }
}
export interface SetDeviceIgnoreInChartsInput {
  deviceId: string
  ignoreInCharts: boolean
}

export interface SetDeviceConsumptionValidationEnabledInput {
  deviceId: string
  consumptionValidationEnabled: boolean
}

export interface SetConsumptionProcessOwnerLockEnabledInput {
  deviceId: string
  consumptionProcessOwnerLockEnabled: boolean
}

export interface SetMeasuringPointColorInput {
  deviceId: string
  measuringPointId: string
  colorGradient: string
}

export interface SetEnergyStatusItemColorInput {
  deviceId: string
  energyStatusItemId: string
  colorGradient: string
}

export const controlStateHubId = 'controlStateHub'
export const plcStateHubId = 'plcStateHub'

export interface ControlValueChangeFailed {
  controlId: string
}

export interface StateChanged {
  controlId: string
  state: {
    [state: string]: any
  }
}

export interface MultipleStateChanged {
  states: StateChanged[]
}

export interface CommandResult {
  correlationId: string
  success: boolean
  error: string
}

export interface SceneModel {
  id: string
  name: string
  iconResourceId: string
  colorGradient: string
  projectId: string
  controlCommands: SceneControlCommandModel[]
  timeSchedules: SceneTimeScheduleModel[]
}

export interface SceneControlCommandModel {
  controlId: string
  command: string
  params: any
}

export interface SceneTimeScheduleModel {
  id: string
  triggerDateTime: string
  isRecurring: boolean
  days: DaysEnum[]
  isEnabled: boolean
}

export enum DaysEnum {
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
  sunday = 'Sunday',
}

export interface DeviceConfirmationStateChanged {
  deviceId: string
  confirmation: boolean
}

export interface ComponentFirmwareReleaseModel {
  id: string
  type: ComponentFirmwareType
  version: string
  state: ComponentFirmwareState
}

export enum ComponentFirmwareType {
  EcoCloudConnectorSmall = 'EcoCloudConnectorSmall',
  EcoCloudConnectorMedium = 'EcoCloudConnectorMedium',
  EcoCloudConnectorLarge = 'EcoCloudConnectorLarge',
  EcoCloudConnectorLargeVirtual = 'EcoCloudConnectorLargeVirtual',
  EcoCloudConnectorLight = 'EcoCloudConnectorLight',
  InverterTrumpf = 'InverterTrumpf',
}

export enum ComponentFirmwareState {
  InTest = 'InTest',
  Active = 'Active',
  Obsolete = 'Obsolete',
}

export interface PlcOperationStateChanged {
  plcId: string
  timeStamp: string
  plcOperationState: PlcOperationState
}

export interface MultiplePlcStateChanged {
  plcOperationStateList: PlcOperationStateChanged[]
}
