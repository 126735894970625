import ApiService from '../services/api.service'
import { PlcModel, ProjectModel } from './models'

export default {
  async loadProjects(): Promise<ProjectModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().projectApi}/Projects/withPlcs`)
    return response.data.value
  },
  async createProject(partnerId: string, project: ProjectModel): Promise<ProjectModel> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().projectApi}/Projects`, {
      partnerId,
      name: project.name,
    })
    return {
      id: response.data.id,
      name: project.name,
      isKioskModeEnabled: false,
      currentUserAuthorization: 'FullAccess',
      plcs: [],
    } as ProjectModel
  },
  async updateProject(project: ProjectModel): Promise<ProjectModel> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().projectApi}/Projects/${project.id}`, project)
    return project
  },
  async deleteProject(id: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().projectApi}/Projects/${id}`, {})
  },
  async createPlc(projectId: string, plc: PlcModel): Promise<PlcModel> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().projectApi}/Plcs`, {
      projectId,
      name: plc.name,
      plcType: plc.plcType,
    })
    return {
      id: response.data.id,
      projectId,
      name: plc.name,
      plcType: plc.plcType,
      hasNotes: false,
      currentUserAuthorization: 'FullAccess',
    } as PlcModel
  },
  async updatePlc(plc: PlcModel): Promise<PlcModel> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().projectApi}/Plcs/${plc.id}`, plc)
    return plc
  },
  async deletePlc(id: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().projectApi}/Plcs/${id}`, {})
  },
}
