import { ScheduleType, mondayToFriday } from './models'
import { SceneModel, DaysEnum, ControlModelV2, SceneControlCommandModel, SceneTimeScheduleModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import moment from 'moment'
import { ControlTypeV2 } from '@ecocoach/domain-store-modules/src/common'

export const getSchedule = (scene: SceneModel) => scene.timeSchedules && scene.timeSchedules[0]

export const defaultScheduleDateTime = () => {
  return moment.utc().startOf('hour').add(1, 'hour').toISOString()
}

export const getScheduleTriggerDateTimeUtc = (scene: SceneModel) => {
  const schedule = getSchedule(scene)
  return schedule && schedule.triggerDateTime || defaultScheduleDateTime()
}

export const isActivating = (scene: SceneModel, activatingSceneIds: string[]): boolean => activatingSceneIds.includes(scene.id)

export const scheduleOfSceneIsValid = (scene: SceneModel, dateTimeUtcNow: string): boolean => {
  return scheduleValid(getSchedule(scene), dateTimeUtcNow)
}

export const scheduleValid = (schedule: SceneTimeScheduleModel, dateTimeUtcNow: string) => {
  const now = dateTimeUtcNow || moment.utc().toISOString()
  return schedule && (schedule.isRecurring || moment.utc(schedule.triggerDateTime) > moment.utc(now)) || false
}

export const scheduleActive = (scene: SceneModel, dateTimeUtcNow: string): boolean => {
  const schedule = getSchedule(scene)
  return scheduleOfSceneIsValid(scene, dateTimeUtcNow) && schedule.isEnabled
}

export const scheduleType = (scene: SceneModel): ScheduleType => {
  const schedule = getSchedule(scene)
  if (schedule && schedule.isRecurring) {
    if (schedule.days.length === 7) {
      return ScheduleType.daily
    }
    if (schedule.days.length === 5 && !schedule.days.some(day => !mondayToFriday.includes(day))) {
      return ScheduleType.mondayToFriday
    } else {
      return ScheduleType.userdefined
    }
  } else {
    return ScheduleType.oneTimePlay
  }
}
export const scheduleText = (scene: SceneModel, dateTimeUtcNow: string, stringResource: (key: string) => string): string => {
  const schedule = getSchedule(scene)
  if (!scheduleValid(schedule, dateTimeUtcNow)) {
    return stringResource('scenes.schedule.none')
  }
  const dateTime = moment.utc(schedule.triggerDateTime)
  if (schedule.isRecurring) {
    if (schedule.days.length === 7) {
      return `${stringResource('scenes.schedule.daily')} ${dateTime.local().format('H:mm')}`
    } else if (schedule.days.length === 5
      && schedule.days.includes(DaysEnum.monday)
      && schedule.days.includes(DaysEnum.thursday)
      && schedule.days.includes(DaysEnum.wednesday)
      && schedule.days.includes(DaysEnum.thursday)
      && schedule.days.includes(DaysEnum.friday)) {
      return `${stringResource('scenes.schedule.mondayToFriday')} ${dateTime.local().format('H:mm')}`
    } else {
      return `${schedule.days.map(day => stringResource(`scenes.schedule.${day.toLowerCase()}`)).join(', ')} ${dateTime.local().format('H:mm')}`
    }
  } else {
    return `${stringResource('scenes.schedule.oneTimePlay')} ${dateTime.format('D.M.YYYY')} ${dateTime.local().format('H:mm')}`
  }
}

export const sceneControlCommandsForLastValue = (control: ControlModelV2): SceneControlCommandModel[] => {
  if (control.type === ControlTypeV2.Toggle || control.type === ControlTypeV2.ConsumptionProcessToggle) {
    return [{
      controlId: control.id,
      command: control.attributes.toggle.command,
      params: control.state![control.attributes.toggle.state] ?? false,
    }]
  } else if (control.type === ControlTypeV2.NumericInput) {
    return [
      ...(control.attributes.beginCommand ? [{
        controlId: control.id,
        command: control.attributes.beginCommand,
        params: null,
      }] : []),
      {
        controlId: control.id,
        command: control.attributes.command,
        params: control.state![control.attributes.state] ?? control.attributes.minValue ?? 0,
      },
      ...(control.attributes.endCommand ? [{
        controlId: control.id,
        command: control.attributes.endCommand,
        params: null,
      }] : []),
    ]
  } else if (control.type === ControlTypeV2.EnumInput) {
    return [{
      controlId: control.id,
      command: control.attributes.command,
      params: control.state![control.attributes.state] ?? control.attributes.options[0]?.id ?? 0,
    }]
  } else if (control.type === ControlTypeV2.StateOfChargeConfiguration) {
    return [
      {
        controlId: control.id,
        command: control.attributes.peakShavingCapacity.command,
        params: control.state![control.attributes.peakShavingCapacity.state] ?? 0,
      },
      {
        controlId: control.id,
        command: control.attributes.loadManagementCapacity.command,
        params: control.state![control.attributes.loadManagementCapacity.state] ?? 0,
      },
      {
        controlId: control.id,
        command: control.attributes.offGridCapacity.command,
        params: control.state![control.attributes.offGridCapacity.state] ?? 0,
      },
    ]
  } else {
    return []
  }
}

export const stateForSceneControl = (control: ControlModelV2, sceneControlCommands: SceneControlCommandModel[]) => {
  if (control.type === ControlTypeV2.Toggle || control.type === ControlTypeV2.ConsumptionProcessToggle) {
    const controlCommand = sceneControlCommands.find(c => c.command === control.attributes.toggle.command)
    return {
      [control.attributes.toggle.state]: controlCommand?.params ?? null,
    }
  } else if (control.type === ControlTypeV2.NumericInput) {
    const controlCommand = sceneControlCommands.find(c => c.command === control.attributes.command)
    return {
      [control.attributes.state]: controlCommand?.params ?? null,
    }
  } else if (control.type === ControlTypeV2.EnumInput) {
    const controlCommand = sceneControlCommands.find(c => c.command === control.attributes.command)
    return {
      [control.attributes.state]: controlCommand?.params ?? null,
    }
  } else if (control.type === ControlTypeV2.StateOfChargeConfiguration) {
    const peakShavingCapacityControlCommand = sceneControlCommands.find(c => c.command === control.attributes.peakShavingCapacity.command)
    const loadManagementCapacityControlCommand = sceneControlCommands.find(c => c.command === control.attributes.loadManagementCapacity.command)
    const offGridCapacityControlCommand = sceneControlCommands.find(c => c.command === control.attributes.offGridCapacity.command)
    return {
      [control.attributes.selfConsumptionCapacity.state]: (100 - (peakShavingCapacityControlCommand?.params + loadManagementCapacityControlCommand?.params + offGridCapacityControlCommand?.params)),
      [control.attributes.peakShavingCapacity.state]: peakShavingCapacityControlCommand?.params ?? null,
      [control.attributes.loadManagementCapacity.state]: loadManagementCapacityControlCommand?.params ?? null,
      [control.attributes.offGridCapacity.state]: offGridCapacityControlCommand?.params ?? null,
    }
  } else {
    return {}
  }
}

export const newSchedule = (id?: string) => ({
  id: id ?? '',
  sceneId: '',
  triggerDateTime: defaultScheduleDateTime(),
  isRecurring: false,
  days: [],
  isEnabled: false,
} as SceneTimeScheduleModel)

